import React, { useEffect, useState } from "react";
import ProfileTopNavBar from "./ProfileTopNavBar";
import { Collapse, Typography, notification, Upload } from "antd";
import { CheckCircleTwoTone, CloseOutlined, ConsoleSqlOutlined } from "@ant-design/icons";
import { updateStoredData } from "../../controllers/accounts/Account";
import { useLocation, useNavigate } from "react-router-dom";
import { beforeUpload } from "../../controllers/common";

function StoreDetailsForm() {
  const location = useLocation();
  const { state } = location;
  const [storeINFO, SetStoreInfo] = useState(location.state.storeINFO || {});
  const [ADDEDITDATA, setAddEditSata] = useState(state ? state : []);
  const [image, setImageList] = useState("");
  const navigate = useNavigate();
  const [errors, serError] = useState([]);

  /*********************************************************
   *  This function is use to handle success notification show
   *********************************************************/
  const handleSuccessMessg = () => {
    notification.open({
      message: "Success!",
      description: " Data Saves Successfully .",
      placement: "topRight",
      duration: 2,
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
  };

  /*********************************************************
   *  This function is use to handle error notification show
   *********************************************************/
  const handleErrorMsg = (msg = "") => {
    notification.open({
      message: "Opps!",
      description: msg,
      placement: "topRight",
      duration: 2,
      icon: <CloseOutlined style={{ color: "red" }} />,
    });
  };

  const handleChange = (e) => {
    try {
      const { name, value } = e.target;
      SetStoreInfo((pre) => ({
        ...pre,
        [name]: value,
      }));
    } catch (error) {
      console.log("StoreDetails Error", error);
    }
  };

  /*********************************************************
   *  This function is use to handle image object
   *********************************************************/
  const handleImageChange = (e) => {
    setImageList(e.fileList[0].originFileObj);
  };

  /*********************************************************
   *  This function is use to handle genger checkbox chnage
   *********************************************************/
  // const onCheckedChange = (e) => {
  //   // console.log('e.target.value',e.target.value);
  //   setAddEditSata((pre) => ({
  //     ...pre,
  //     gender: e.target.value,
  //   }));
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let form = new FormData(e.target);
    form.append("image", image ? image : null);
    form.append("editId", storeINFO?._id);
    form.append("ipAddress", sessionStorage.getItem("IP_ADDRESS"));

    const isValidate = validateFormData(form);
    console.log("isValidate", isValidate);

    if (isValidate) {
      const res = await updateStoredData(form);
      console.log("response", res);
      if (res.status === true) {
        setImageList("");
        handleSuccessMessg();
        navigate("/profile");
      } else {
        handleErrorMsg(res?.message || "An error occurred while saving data.");
      }
    } else {
      handleErrorMsg("Please fill required fields.");
    }
  };
  //End

  /*********************************************************
   *  This function is use to validate form data before submit
   *********************************************************/
  const validateFormData = (formData) => {
    if (formData.get('shop_name') === 'undefined' || formData.get('shop_name') === null || formData.get('shop_name').trim() === '') {
      serError((preError) => ({
        ...preError,
        shop_name: "shop_name is required"
      }));
      return false;
    } else if (formData.get('phone') === 'undefined' || formData.get('phone') === null || formData.get('phone').trim() === '') {
      serError((preError) => ({
        ...preError,
        phone: "phone is required"
      }))
      return false;
    } else if (formData.get('email') === 'undefined' || formData.get('email') === null || formData.get('email').trim() === '') {
      serError((preError) => ({
        ...preError,
        email: "email is required"
      }))
      return false;
    } else if (formData.get('landmark') === 'undefined' || formData.get('landmark') === null || formData.get('landmark').trim() === '') {
      serError((preError) => ({
        ...preError,
        email: "landmark is required"
      }))
      return false;
    } else if (formData.get('address') === 'undefined' || formData.get('address') === null || formData.get('address').trim() === '') {
      serError((preError) => ({
        ...preError,
        address: "address is required"
      }))
      return false;
    } else if (formData.get('city') === 'undefined' || formData.get('city') === null || formData.get('city').trim() === '') {
      serError((preError) => ({
        ...preError,
        city: "city is required"
      }))
      return false;
    } else if (formData.get('country') === 'undefined' || formData.get('country') === null || formData.get('country').trim() === '') {
      serError((preError) => ({
        ...preError,
        country: "country is required"
      }))
      return false;
    } else {
      return true;
    }
  }

  // const validateFormData = (formData) => {
  //   let isValid = true;
  //   let errorMessages = {};
  //   const requiredFields = [
  //     "shop_name",
  //     "phone",
  //     "email",
  //     "address",
  //     "city",
  //     "country",
  //     "image"
  //   ];

  //   requiredFields.forEach((field) => {
  //     if (!formData.get(field) || formData.get(field).trim() === "") {
  //       errorMessages[field] = `${field} is required`;
  //       isValid = false;
  //     }
  //   });
  //   serError(errorMessages);
  //   return isValid;
  // };

  useEffect(() => {
    document.title = `Frisbee Store || Add/Edit User`;
  }, []);

  return (
    <div>
      <ProfileTopNavBar />
      <div className="container-fluid">
        <div className="card shadow mb-4 dataBase_table_Add_Data">
          <div className="card-body">
            <div className="responsive">
              <form onSubmit={handleSubmit}>
                <div class="py-1 text-start">
                  <span className="profile_store_details_heading">
                    Store Details
                  </span>
                </div>
                <div className="store_details_all_form_section">
                  <div class="form-row">
                    <div className="store_details_form_owner_name">
                      <div class="col">
                        <label
                          for="lastName"
                          className="profile_label_store_name"
                        >
                          Store Name
                        </label>
                        <input
                          className="form-control all_input_tag_tagenter_product_"
                          type="text"
                          name="shop_name"
                          placeholder="Shop Name"
                          id="shop_name"
                          value={storeINFO?.shop_name || ""}
                          onChange={handleChange}
                        />
                        {errors?.shop_name ? (
                          <p style={{ color: "red" }}>{errors.shop_name}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div class="col">
                        <label
                          for="phoneNo"
                          className="profile_label_store_name"
                        >
                          Phone No.
                        </label>
                        <input
                          className="form-control all_input_tag_tagenter_product_"
                          type="number"
                          name="phone"
                          placeholder="+91"
                          id="phoneNo"
                          value={storeINFO?.phone || ""}
                          onChange={handleChange}
                        />
                        {errors?.phone ? (
                          <p style={{ color: "red" }}>{errors.phone}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div class="col">
                        <label for="email" className="profile_label_store_name">
                          Email Address*
                        </label>
                        <input
                          className="form-control all_input_tag_tagenter_product_"
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          id="email"
                          value={storeINFO?.email || ""}
                          onChange={handleChange}
                        />
                        {errors?.email ? (
                          <p style={{ color: "red" }}>{errors.email}</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="form-row col-12">
                    <div className="store_details_form_owner_name">
                      <div class="col">
                        <label
                          for="landMark"
                          className="profile_label_store_name"
                        >
                          Landmark
                        </label>
                        <input
                          type="text"
                          class="form-control all_input_tag_tagenter_product_"
                          id="landmark"
                          name="landmark"
                          placeholder="Landmark"
                          value={storeINFO?.street}
                          onChange={handleChange}
                        />
                        {errors?.landmark ? (
                          <p style={{ color: "red" }}>{errors.landmark}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div class="col-8">
                        <label
                          for="ownerName"
                          className="profile_label_store_name"
                        >
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-control all_input_tag_tagenter_product_"
                          name="address"
                          placeholder="Address"
                          id="address"
                          value={storeINFO?.address || ""}
                          onChange={handleChange}
                        />
                        {errors?.address ? (
                          <p style={{ color: "red" }}>{errors.address}</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div className="store_details_form_owner_name">
                      <div class="col">
                        <label for="city" className="profile_label_store_name">
                          City
                        </label>
                        <input
                          type="text"
                          className="form-control all_input_tag_tagenter_product_"
                          name="city"
                          placeholder="City"
                          id="city"
                          value={storeINFO?.city || ""}
                          onChange={handleChange}
                        />
                      </div>
                      <div class="col">
                        <label
                          for="Country"
                          className="profile_label_store_name"
                        >
                          Country
                        </label>
                        <input
                          type="text"
                          class="form-control all_input_tag_tagenter_product_"
                          id="country"
                          name="country"
                          placeholder="Country"
                          value={storeINFO?.country || ""}
                          onChange={handleChange}
                        />
                        {errors?.country ? (
                          <p style={{ color: "red" }}>{errors.country}</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* Image */}
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label className="profile_label_store_name all_top_and_bottom_for_input_text">Image</label>
                      <div className="inventory_upload_file_main_div">
                        <Upload
                          onChange={handleImageChange}
                          name="image"
                          maxCount="1"
                          listType="picture-card"
                          className="product_inventory_edit_upload"
                          beforeUpload={beforeUpload}
                        >
                          <button
                            className="inventroy_edit_page_antd_uplaod_button"
                            type="button"
                          >
                            <div className="inventroy_edit_page_antd_choose_file">
                              Choose File
                            </div>
                          </button>
                        </Upload>
                      </div>
                        <p style={{ color: "blue" }} className="all_top_and_bottom_for_input_text">
                        *Note: Please choose an image of JPEG/JPG/PNG format
                        file.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="store_details_form_submit_button">
                  <button
                    type="submit"
                    class="btn btn-dark store_details_form_btn"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoreDetailsForm;
